.footer {
  width: 100%;
  /* top: 90%; */
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  /* margin-top: 5rem; */
  /* border-top: 1px solid gray */
}

.spacer {
  flex: 1;
}

.footer__items {
  list-style: none;
  /* padding: 0; */
  margin: 0.25rem 0.5rem;
  font-size: 0.75rem
  /* display: none; */
}

.footer__text {
  /* color: rgb(190, 190, 190); */
  color: var(--color);
}

.name {
  color: white;
  margin-left: 1rem;
  cursor: pointer;
}

.userImage {
  margin-top: 0.25rem;
  margin-left: 1rem;
  border-radius: 0.1rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .main-nav__items {
    display: flex;
  }
}
