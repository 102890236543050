.auth-form {
  /* width: 90%; */
  width: 80%;
  margin: auto;
  padding: 1rem;
  margin-top: 5rem;
  background: white;
  border-radius: 0.2rem;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.10);
  /* border: 1px solid #3b0062; */
  /* border-radius: 5px; */
}

.auth-form__loader {
  display: flex; 
  justify-content: center;
}

.auth-form__passwordLength {
  /* margin-top: 1rem; */
  margin-bottom: 1rem;
}

.auth-form__foregtPassword {
  margin-top: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.auth-form__signupLink {
  margin-top: 30px;
  text-decoration: underline;
  color: var(--color);
}

.auth-form__showPasword {
  margin-bottom: 30px;
  /* text-decoration: underline; */
}

.auth-form__emailVerifyMessage {
  margin-top: 1rem;
  font-weight: bolder;
  /* text-decoration: underline; */
}

.auth-form__passwordResetMessage {
  margin-top: 1rem;
  font-weight: bolder;
}
.auth-form__checkbox {
  margin-bottom: 2rem;
}

.auth-form__title {
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 2rem;
}

.auth-form__logoTitle {
  display: flex;
  justify-content: flex-end;
  gap: 0.25rem;
  align-items: center;
  padding-bottom: 2rem;
  font-size: 0.8rem;
}

.auth-form__logo {
  height: 1rem;
  width: 1rem;
}

.auth-form__notHaveAccount {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.auth-form__notHaveAccountSignup {
  text-decoration: none;
  font-weight: bold;
  color: inherit;
}

.auth-form__otherServiceLoginContainer {
  text-align: center;
}

.auth-form__otherServiceLoginButton {
    width: 70%;
    /* background: lightgray; */
    /* font-weight: bolder; */
    /* border: 0.5px solid gray; */
    text-align: center;
    margin-left: 15%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 10px;
}

.auth-form__googleLogin {
  color: rgb(214, 0, 0);
  background-color: rgb(255, 234, 234);
}

.auth-form__facebookLogin {
  color: blue;
  background-color: rgb(226, 226, 255);
}

.auth-form__recaptchaV2Container {
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.auth-form__recaptchaV2title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.auth-form__tfaContainer {
  text-align: right;
}

.auth-form__tfaLink {
  font-size: 0.4rem;
  text-align: right;
  padding-top: 4rem;
  text-decoration: none;
  color: gray;
  cursor: default;
}

@media (min-width: 768px) {
  .auth-form {
    width: 30rem;
  }
}
