.modal {
  position: fixed;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 0vh;
  /* background: white; */
  background: var(--background-color);
  border: 0.5px solid #999;
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow-y: auto;
}

.modal__header {
  border-bottom: 2px solid #3b0062;
}

.modal__header h1 {
  font-size: 1.5rem;
  color: #3b0062;
  margin: 1rem;
}

.modal__content {
  padding: 1rem;
}

.modal__actions {
  padding: 1rem;
  text-align: right;
}

.modal__actions button {
  margin: 0 0.5rem;
}

.modal__loader {
  display: flex; 
  justify-content: center;
}

.modal2 {
  position: fixed;
  width: 90%;
  height: 70%;
  left: 5%;
  top: 0vh;
  /* background: white; */
  background: var(--background-color);
  border: 0.5px solid #999;
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(179, 130, 130, 0.26);
  overflow-y: auto;
}

.fullImageModal {
  position: fixed;
  max-width: 95%;
  max-height: 90%;
  left: 1%;
  top: 1vh;
  /* background: white; */
  /* background: var(--background-color); */
  /* border: 0.5px solid #999; */
  /* border-radius: 5px; */
  z-index: 200;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  overflow: auto;
}

.confirmModal {
  position: fixed;
  width: 90%;
  left: 5%;
  top: 40vh;
  background: white;
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  /* overflow-y: scroll; */
}

.createModal {
  position: fixed;
  width: 50%;
  left: 20%;
  top: 3.5rem;
  background: var(--background-color);
  border: 0.5px solid rgb(110, 110, 110);
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  /* overflow-y: scroll; */
}

.languageModal {
  position: fixed;
  /* width: 90%; */
  max-width: 80%;
  max-height: 80%;
  left: 10rem;
  /* top: 0vh; */
  top: 2vh;
  background: white;
  /* background: var(--background-color); */
  border: 0.5px solid #999;
  border-radius: 5px;
  z-index: 200;
  box-shadow: 0 2px 8px rgba(179, 130, 130, 0.26);
  overflow: auto;
  /* padding:0.25rem; */
}

.modal--error {
  height: 30%;
}

@media (min-width: 768px) {
  .modal {
    width: 40rem;
    left: calc((100% - 40rem) / 2);
  }
}
