.languageSelect__container {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.languageSelect__content {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.languageSelect__closeModalButton {
  text-align: right;
  font-size: 1.5rem;
  cursor: pointer;
}
