.input {
  margin: 1rem 0;
  width: 100%;
  width: 90%;
  position:relative;
}

.input label {
  display: block;
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.input input,
.input textarea {
  display: block;
  font: inherit;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: var(--input-background);
  color: var(--color);
}

.input .touched.invalid {
    border-color: red;
    /* background: #ffc2c2; */
}

.input input:focus,
.input textarea:focus {
  outline: none;
  border-color: #3b0062;
  color: #3b0062;
  color: var(--color);
}

.input__filePickElement {
  margin-bottom: 0.25rem;
}






.inputEmoji__container {
  position:relative;
  margin: 1rem 0;
  width: 100%;
}

.inputEmoji__input {
  width: 100%;
}

.inputEmoji__input:focus {
  outline: none;
}

.inputEmoji__inputButton {
  position:absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  /* top: 30px; */
  /* width: 60px; */
}