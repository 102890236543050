/* .languageList__container {
  position: fixed;
  top: 5%;
  left: 50px;
  z-index: 200;
  background: white;
  max-height: 80%;
  overflow: auto;
} */

.languageList__listElement {
  cursor: pointer;
  margin-bottom: 1rem;
}

ul {
  padding-left: 0px
}