.tfaBody {
  width: 95%;
  max-width: 40rem;
  margin: 0 auto;
  height: 100vh;
  padding: 6rem 0;
}

.tfaInput {
  display: block;
  font: inherit;
  padding: 0.25rem 0.5rem;
  width: 50%;
  border-radius: 3px;
  border: 1px solid #ccc;
  background: var(--input-background);
  color: var(--color);
}

.tfaDisableTitle {
  padding-top: 5rem;
}

.tfaDisableButton {
  padding-top: 1rem;
}

.tfaDisableLink {
  padding-top: 2rem;
  cursor: pointer;
}